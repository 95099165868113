<template>
  <div class="Free">
    <h1>免费</h1>
  </div>
</template>

<script>
export default {
  name: 'Free',
};
</script>

<style></style>
